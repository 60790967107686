﻿@import (reference) "lib/bootstrap-references.less";
@imageHeight:265px;

.vcard {
    max-width: 370px;
    float: left;
    border: solid 1px #cccccc;
    padding: 30px;
    margin-right: 30px;
    margin-bottom: 30px;

    h3 {
        margin-top: 0px;
        text-transform: capitalize;
    }

    .email {
        -ms-word-break: break-word;
        word-break: break-word;
    }

    i {
        width: 20px;
        margin-right: 3px;
    }
}
.vcard .fn, .vcard .org {
    font-weight: 700;
}

.phone-icon {
    padding-left: 3px;
    padding-top: 2px;
}

.email-icon {
    font-size: 12px;
    padding-left: 3px;
}

.address-icon {
    float: left;
    padding-left: 2px;
    padding-top: 2px;
}

.edit-address, .remove-address, .ask a, .confirm-yes a, .confirm-no a {
    padding: 4px 10px;
}

.confirm-no a, .ask a.remove-address {
    pointer-events: none;
}

.ask {
    overflow: hidden;
    width: 100%;
    float: left;
    cursor: pointer;
}

.confirm-yes, .confirm-no {
    overflow: hidden;
    width: 0;
    float: left;
    cursor: pointer;
}

.confirm-yes button, .confirm-yes a {
    border-radius: 4px 0px 0px 4px;
}

.confirm-no button, .confirm-no a {
    border-radius: 0px 4px 4px 0px;
}

.slider-confirmation-container {
    overflow: hidden;

    div {
        -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
    }
}

.expire-date {
    padding-top: 4px;
    &.date-is-expired {
        color: #ff0000;
    }
}

#new-payment-method-container{
    .form-group{
        margin:5px;
    }
}
.edit-expiration {
    padding: 0 !important;
    margin: 0 !important;
}

.expiration-date {
    float: left;
    padding-right: 10px;
    margin-top: 3px;
}


.credit-card.ccard {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px;
    margin-bottom: 20px;
    border: solid 1px black;
    max-width: 420px;
    width: 350px;
    margin-right: 20px;
    float: left;

    h3 {
        margin-top: 0;
        text-transform: capitalize;
    }
}

.confirm-no, .confirm-yes, .ask, .slider-confirmation-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.address-form {
    clear:both;
}

.card-holder {
    overflow: hidden;
}

.card-contents {
    display:inline;
}

.custom-amount{
    max-width: 150px;
}

.address-modal {
    .modal-dialog {
        max-width: 1000px;
        width: auto;

        .modal-body {
            padding-top: 0;

            label.checkbox input {
                margin-right: 5px;
            }

            .selectable-address-group, .add-address-container {
                .clearfix;
                margin: 0 45px;
            }

            .add-address-container {
                .btn.submit-new-address {
                    width: 100%;
                }

                legend {
                    display: none;
                }
            }
        }
    }
}

.span-11, .span-10, .span-9 {
    .inline-address-form fieldset.address-form {
        .horizontal-form-group {
            .horizontal-form-label {
                .make-sm-column(3);
            }

            .horizontal-form-control {
                .make-sm-column(9);
            }
        }
    }
}

.span-8, .span-7, .span-6, .span-5, .span-4 {
    .inline-address-form fieldset.address-form {
        .horizontal-form-group {
            .horizontal-form-label {
                .make-sm-column(4);
            }

            .horizontal-form-control {
                .make-sm-column(8);
            }
        }
    }
}

.span-3, .span-2, .span-1 {
    .inline-address-form fieldset.address-form {
        .horizontal-form-group {
            .horizontal-form-label {
                .make-xs-column(12);
                text-align: left;
            }

            .horizontal-form-control {
                .make-xs-column(12);
            }
        }
    }
}

.address-block {
    overflow: hidden;
    display: flex;
}

